<template>
  <transition name="fadeIn" mode="in-out">
    <li v-show="!isLoading">
      <a class="search-page-person" v-if="!!section && section.name === 'People'" :href="'#'" :title="item.title">
        <div class="search-page-person__row">
          <div class="person-wrapper">
            <div class="image-wrapper" v-if="!!item.image && !!item.image[0]">
              <div class="object-fit-fallback aspect-ratio-box aspect-ratio-box--8-5">
                <img
                  class="lazyload"
                  :src="!!item.image && !!item.image[0] ? item.image[0].url : '/assets/img/placeholder.jpg'"
                  :alt="!!item.image && !!item.image[0] ? item.title : ''"
                  data-sizes="auto"
                  onerror="this.src = '/assets/img/placeholder.jpg';"
                >
              </div>
            </div>
            <h4>
              {{!!item.title && item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}
            </h4>            
          </div>
          <div class="info-wrapper"> 
            <div class="info-wrapper__details">
              <div :v-if="!!item.title && item.title.length > 0" class="info-wrapper__title">{{!!item.title && item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}</div>
              <div :v-if="item.titlePosition" class="info-wrapper__position">{{ item.titlePosition }}</div>
              <div :v-if="item.officeCategory" class="info-wrapper__location">{{ item.officeCategory[0].title }}</div>
              <div :v-if="!!item.phoneNumber && item.email.phoneNumber > 0" class="info-wrapper__phone">{{ item.phoneNumber }}</div>
              <div :v-if="!!item.email && item.email.length > 0" class="info-wrapper__email">{{ item.email }}</div>              
            </div>                        
          </div>
        </div>      
      </a>      
      <!-- Normal search result -->
      <div class="search-page-item" v-else>
        <div class="search-page-item__column">
          <div class="search-page-item__row">
            <div class="info-wrapper">
              <div class="info-wrapper__section">
                <span v-if="!!section">
                  <template>
                    {{ section.name|trans }}
                  </template>
                </span>
              </div>
              <a class="" :href="item.url" :title="item.title">
                <h4>
                  {{!!item.title && item.title.length > 117 ? item.title.slice(0, 114) + '...' : item.title}}
                </h4>
              </a>
              <div v-if="section.name === 'Office'" class="mb-2">
                <a :v-if="phoneNumber" :href="'tel:' + phoneNumber" class="js-track-link" data-track-category="phone">{{ item.phoneNumber }}</a>
                <a :v-if="!!item.email && item.email.length > 0" :href="'mailto:' + item.email" class='js-track-link' data-track-category='email'>{{ item.email }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </transition>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

// moment.locale('sv');

export default {
  name: 'SearchPageItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    sections: {
      type: Array,
      default: []
    }
  },
  computed: {
    phoneNumber: function () {
      return !!this.item.phoneNumber ? this.item.phoneNumber.replace('/[^0-9\+]/', '').replace(/ /g, '') : ''
    },
    section: function () {
      return this.sections.find(section => parseInt(section.id) === parseInt(this.item.sectionId));
    }
  },
  filters: {
    unixToIso: function(unix_timestamp) {
      return moment(unix_timestamp).format('DD MMM YYYY');
    }
  },
  methods: {
    formatDate: function(date, format) {
      const momentDate = moment.unix(date);

      switch (format) {
        case 'time':
          return momentDate.format('HH:mm');
        break;
        case 'day':
          return momentDate.format('dddd');
        break;
        case 'date':
          return momentDate.format('DD.MM');
        break;
      }
    },
  }
};
</script>
