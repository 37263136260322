var toggleChildren = function() {
  "use strict";
  var toggleLink = $('.toggle-children');
  toggleLink.on('click', function(e) {
    e.preventDefault();
    var $link = $(this),
        targetId = $link.attr('href');

    if ($(targetId).length) {
      $link.toggleClass('showing-children');
      $(targetId).toggleClass('show-children');
    }

    return false;
  });
};

$(document).ready(function(){
  "use strict";
  toggleChildren();
});
