var jumpToSection = function() {
  "use strict";
  if ($('#jumpToSection').length) {
    var sectionID = $('#jumpToSection').val(),
        offsetHeight = 57,
        sectionOffset = ($('#' + sectionID).offset().top) - offsetHeight;

    $(window).scrollTop(sectionOffset);
  }
};

var scrollToElement = function(elem, padding, speed, easing) {
  "use strict";
  elem = typeof elem !== 'undefined' ? elem : "html, body";
  padding = typeof padding !== 'undefined' ? padding : 0;
  speed = typeof speed !== 'undefined' ? speed : 400;
  easing = typeof easing !== 'undefined' ? easing : "swing";
  var mainElement = $('html, body'),
      $window = $(window),
      removeScrollingClass = function() {
        $('html').removeClass('scrolling');
      };

  if ($(elem).length) {
    var elemOffset = $(elem).offset().top - $('body').offset().top;
    mainElement.animate({
      scrollTop: (elemOffset - padding) + "px"
    }, speed, easing, removeScrollingClass);
  } else if (elem === '#top') {
    mainElement.animate({
      scrollTop: 0
    }, speed, easing, removeScrollingClass);
  } else if (elem === '#bottom') {
    var wheight = $window.height();
    mainElement.animate({
      scrollTop: wheight
    }, speed, easing, removeScrollingClass);
  } else if (elem === '#down') {
    mainElement.animate({
      scrollTop: "" + ($window.scrollTop() + 300)
    }, speed, easing, removeScrollingClass);
  }
};

var scrollLink = function() {
  "use strict";
  $(document).on('click', '.scroll-to', function(e) {
    e.preventDefault();
    var $this = $(this),
        target = null,
        offset = $('#header').outerHeight();

    if (!$this.data('target')) {
      target = $this.attr('href');
    } else {
      target = $this.data('target');
    }
    if ($this.data('offset')) {
      offset = offset + $this.data('offset');
    }

    $('html').addClass('scrolling');
    scrollToElement(target, offset);

    return false;
  });
};

$(window).on("load", function() {
  "use strict";
  scrollLink();
  jumpToSection();
});
