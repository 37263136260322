<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader" v-show="isLoading || !isReady" leave-stagger="500">
      <div class="cardLoader__wrapper">
        <div class="cardLoader__wrapper__level1">
          <div class="cardLoader__wrapper__level2">
            <div class="cardLoader__lines-wrapper">
              <h3 class="line-medium-large"></h3>
              <div class="date">
                <div class="line-medium"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'EventCardLoadingIndicator',
  props: ['isLoading', 'isReady']
};
</script>
