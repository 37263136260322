var resizingClass = function () {
  "use strict";
  var resizeTimer,
    $body = $("body"),
    resizingClass = "resizing";

  $(window).on("resize", function (e) {
    if (!$body.hasClass(resizingClass)) {
      $body.addClass(resizingClass);
    }
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      $body.removeClass(resizingClass);
      equalizerReflow();
      $body.trigger("resized");
    }, 250);
  });
};

var inputFocus = function () {
  "use strict";
  $("input")
    .on("focus", function () {
      $(this)
        .parent()
        .addClass("focusing");
      if (
        $(this)
          .parent()
          .hasClass("input")
      ) {
        $(this).keydown(function (event) {
          if (event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        });
      }
    })
    .on("blur", function () {
      $(this)
        .parent()
        .removeClass("focusing");
    });
};

var styledSelect = function () {
  "use strict";
  $("select.styled").not('select.styled.rendered').each(function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();
    if ($this.parent(".select-wrapper").length === 0) {
      $this.wrap("<div class='select-wrapper'></div>");
      $this.after("<span class='holder'>" + selectedOption + "</span>");
    } else {
      $this.next(".holder").text(selectedOption);
    }
  });
  $(document).on("change", "select.styled", function () {
    var $this = $(this),
      selectedOption = $this.find(":selected").text();
    $this.next(".holder").text(selectedOption);
  });
};

var toggleSearch = function () {
  "use strict";
  var $body = $("body"),
    openClass = "header-search-open",
    $input = $(".header-search input"),
    closeSearch = function () {
      $body.removeClass(openClass);
      // $input.blur();
    },
    openSearch = function () {
      $body.addClass(openClass);
      // $input.focus();
    };

  $(".toggle-search").on("click", function (e) {
    e.preventDefault();
    if ($body.hasClass(openClass)) {
      closeSearch();
    } else {
      openSearch();
    }
    return false;
  });

  $(window).resize(function () {
    if ($body.hasClass(openClass)) {
      closeSearch();
    }
  });

  $(document).on("click", function (e) {
    if (!$(e.target).is(".header-search, .header-search *, .general-search, .general-search *")) {
      $body.removeClass(openClass);
    }
  });
};

var headerDropdown = function () {
  "use strict";

  const $mainNav = $(".main-navigation")
  window.addEventListener('click', (e) => {
    const openMenus = document.querySelectorAll(".main-navigation .has-submenu.open");
    if (!e.target.closest(".main-navigation .has-submenu")) {
      if (openMenus.length) {
        openMenus.forEach(element => {
          element.classList.remove('open');
          $mainNav.removeClass("dropdown-open");
        });
      }
    }
  });
  $(".main-navigation .has-submenu").on('click', (event) => {
    event.stopImmediatePropagation();

    const target = event.target;

    const openMenus = document.querySelectorAll(".main-navigation .has-submenu.open");
    if (target.classList.contains('top-level-link')) {
      if (openMenus.length) {
        openMenus.forEach(element => {
          if (target.dataset.id !== element.dataset.id) {
            element.classList.remove('open');
            $mainNav.removeClass("dropdown-open");
          }
        });
      }
      if (target.classList.contains('open')) {
        target.classList.remove("open");
        target.parentElement.classList.remove("open");
        $mainNav.removeClass("dropdown-open");
      } else {
        target.classList.add("open");
        target.parentElement.classList.add("open");
        $mainNav.addClass("dropdown-open");
      }
    }
  })
};

var headerSubmenus = function () {
  "use strict";

  // window.addEventListener('click', (e) => {
  //   const openMenus = document.querySelectorAll(".main-navigation .has-submenu.open");
  //   if (!e.target.closest(".main-navigation .has-submenu")) {
  //     if (openMenus.length) {
  //       openMenus.forEach(element => {
  //         element.classList.remove('open');
  //       });
  //     }
  //   }
  // });
  $(".main-navigation .has-submenu .menu-level-2").on('click', (event) => {
    event.stopImmediatePropagation();

    const target = event.target;

    const targetParent = target.closest('.product-dropdown')

    const allLevelTwoMenus = targetParent.querySelectorAll('.menu-level-2');
    const allSubmenus = targetParent.querySelectorAll('.list-level-3');

    if (allLevelTwoMenus) {
      allLevelTwoMenus.forEach(element => {
        element.classList.remove('active');
      });
    };

    if (allSubmenus) {
      allSubmenus.forEach(element => {
        element.classList.remove('active');
      });
    };

    if (target.dataset.levelTwoId) {
      const subMenu = document.querySelector(`[data-level-three-parent-id="${target.dataset.levelTwoId}"]`);
      if (subMenu) {
        subMenu.classList.add('active');
        target.classList.add('active');
      }
    }
  })
};

var equalizerReflow = function () {
  "use strict";
  var $equalizers = $("[data-equalizer]");
  $.each($equalizers, function () {
    new Foundation.Equalizer($(this));
  });
};

var styledCheckboxAndRadio = function () {
  "use strict";
  var $inputs = $('input[type="checkbox"], input[type="radio"]');
  $inputs.parent("label").addClass("styled");
  $inputs.after('<span class="dummy"></span>');
};

var heroBlur = function () {
  "use strict";
  if ($(".hero--blurred").length) {
    var $window = $(window),
      once = true,
      $hero = $(".hero--blurred"),
      limit = 30,
      toggleBlurred = function () {
        var windowTop = $window.scrollTop();

        if (windowTop > limit) {
          once = false;
          $hero.addClass("blurred");
        } else {
          once = true;
          $hero.removeClass("blurred");
        }
      };

    setInterval(toggleBlurred, 10);
  }
};

var questionnaire = function () {
  "use strict";
  if ($(".questionnaire-form").length) {
    var $input = $(".custom-checkbox"),
      $button = $(".questionnaire-button"),
      $last = $(".last-checkbox");

    $input.on("click", function () {
      $(this).toggleClass("check");
      $(this)
        .parent()
        .next()
        .removeClass("hidden");
      if ($input.length === $(".check").length) {
        $button.removeClass("hidden-button");
      } else {
        $button.addClass("hidden-button");
      }
    });
  }
};

// Fix for blurry iframe embed thumbnail images inside of accordions
var loadIframe = function () {
  "use strict";
  var $iframe = $('iframe');

  if ($('.accordion').length) {
    if ($iframe.length) {
      $('.accordion-title').on("click", function () {
        $iframe.attr('src', $iframe.attr('src'));
      });
    }
  }
};

var dropdownFunction = function (toggleButtonSelector, dropdownSelector, closeButtonSelector) {
  var toggleButtons = document.querySelectorAll(toggleButtonSelector);
  var dropdown = document.querySelector(dropdownSelector);
  var searchInput = document.querySelector('#dropdown-search');
  var toggleDropdown = () => {
    if (Foundation.MediaQuery.atLeast('medium')) {
      if (dropdown.classList.contains('search-open')) {
        dropdown.classList.remove('search-open');
        document.removeEventListener('click', closeDropdown);
      } else {
        dropdown.classList.add('search-open');
        document.addEventListener('click', closeDropdown);
        if ($('.search-dropdown').hasClass('search-open')) {
          searchInput.focus();
        }

        if (dropdownSelector == '#search-dropdown') {
          document.getElementById('dropdown-search').focus();
        }
      }
    }
  }
  var closeDropdown = (event) => {
    if (event.target.closest(toggleButtonSelector) || event.target.classList.contains('header-navigations') || event.target.classList.contains('preventClose')) return;
    if (!event.target.closest(dropdownSelector) || !!event.target.closest(closeButtonSelector)) {
      dropdown.classList.remove('search-open');
      document.removeEventListener('click', closeDropdown);
    }
  }

  _.forEach(toggleButtons, function (toggleButton) {
    toggleButton.addEventListener('click', toggleDropdown);
  });
}

import Rellax from 'rellax';
var imageParallax = function () {
  if ($('.rellax').length) {
    // Also can pass in optional settings block
    var rellax = new Rellax('.rellax', {
      wrapper: null,
      speed: -2,
      center: true,
      round: true,
      vertical: true,
      horizontal: false,
      breakpoints: [576, 768, 1201]
    });
  }
}

// get all forms
var allForms = document.forms;

// if all forms
if (allForms) {
  var css = ".form-loader,.form-loader:after{border-radius:50%;width:15px;height:15px}.form-loader{margin:0 auto;font-size:10px;position:relative;text-indent:-9999em;border-top:2px solid rgba(255,255,255,.2);border-right:2px solid rgba(255,255,255,.2);border-bottom:2px solid rgba(255,255,255,.2);border-left:2px solid #fff;-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0);-webkit-animation:load8 1.1s infinite linear;animation:load8 1.1s infinite linear}@-webkit-keyframes load8{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes load8{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}";
  var head = document.head || document.getElementsByTagName('head')[0];
  var style = document.createElement('style');

  head.appendChild(style);
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));

  // loop through all forms
  for (var i = 0; i < allForms.length; ++i) {
    var form = allForms[i];
    // on form submit
    form.addEventListener('submit', function (event) {
      // add loading html inside button
      var submitButton = this.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.disabled = true;
        submitButton.innerHTML = '<div class="form-loader">Loading...</div>';
      }
    });
  }
}

var stickyScroll = function () {
  if ($('#sticky').length) {
    var window_width = jQuery(window).width();

    if (window_width < 768) {
      jQuery("#sticky").trigger("sticky_kit:detach");
    } else {
      make_sticky();
    }

    jQuery(window).resize(function () {
      window_width = jQuery(window).width();

      if (window_width < 768) {
        jQuery("#sticky").trigger("sticky_kit:detach");
      } else {
        make_sticky();
      }
    });

    function make_sticky() {
      jQuery("#sticky").stick_in_parent({ offset_top: 160 });
    }
  }
};

var popupModalInfo = function () {

  // reset leadoo email in window object
  window.leadoo = { email: '' };

  var popUpForms = $('a[leadoo-src]');

  popUpForms.each(function (index) {
    var $this = $(this);

    $this.on("click", function () {

      var leadooInpageBotSrc = $this.attr('leadoo-src');
      var div = document.querySelector('.leadoo-embed');

      var leadooContainer = document.createElement('div');
      leadooContainer.innerHTML = leadooInpageBotSrc;
      div.innerHTML = '';
      if (div.innerHTML.length == 0) {

        setTimeout(function () {
          // Code to be executed after the delay
          div.appendChild(leadooContainer);

        }, 200);
      }

    });
  });

  if ($('.banner--contact').length) {
    $(".banner--contact").each(function (index) {
      var $this = $(this);

      $this.find($('.button--pop-up')).on("click", function () {

        // assign new leadoo information to window object on every banner button click
        var contactEmail = $this.find($('.contact-info__email'));
        window.leadoo = { email: contactEmail.text() };

      });
    });
  }

  // check if hero element exists with form popup functionality toggled on
  if ($('.leadoo-cta .button--pop-up').length) {

    // if contact banner exists on same page, assign window email object the first contact banners email value
    if ($('.banner--contact').length) {
      $(".leadoo-cta .button--pop-up").on("click", function () {

        var contactEmail = $('.contact-info__email').first();
        window.leadoo = { email: contactEmail.text() };

      });

      // otherwise use the navigation form and reset window email object
    } else {

      $(".leadoo-cta .button--pop-up").on("click", function () {
        var leadooSrc = '<ld-slot name="navigation-slot" />';
        if (window.siteHandle == 'sv') {
          var leadooSrc = '<ld-slot name="navigation-slot-sv" />';
        } else if (window.siteHandle == 'nl') {
          var leadooSrc = '<ld-slot name="navigation-slot-nl" ></ld-slot>';
        }

        $(".leadoo-cta .button--pop-up").attr('leadoo-src', leadooSrc);
        window.leadoo = { email: '' };

      });
    }
  }

}

var showAllServices = function () {
  var showAllButton = document.querySelector('#show-all-services');
  var hiddenServices = document.querySelector('.hidden-services');

  if (showAllButton && hiddenServices) {
    showAllButton.addEventListener('click', (event) => {
      if (hiddenServices.classList.contains('show')) {
        hiddenServices.classList.remove('show');
      } else {
        hiddenServices.classList.add('show');
        showAllButton.classList.add('hidden');
      }
    })
  }
}

var languageSwitcher = function () {

  const SPACEBAR_KEY = ["Spacebar", " "];
  const ENTER_KEY = "Enter";
  const DOWN_ARROW_KEY = "ArrowDown";
  const UP_ARROW_KEY = "ArrowUp";
  const ESCAPE_KEY = ["Escape", "Esc"];

  const list = document.querySelector(".dropdown__list");
  const listContainer = document.querySelector(".dropdown__list-container");
  const dropdownArrow = document.querySelector(".dropdown__arrow");
  const listItems = document.querySelectorAll(".dropdown__list-item-inner");
  const dropdownSelect = document.querySelector(".dropdown-select");
  const dropdownSelectedNode = document.querySelector("#dropdown__selected");
  const listItemIds = [];

  dropdownSelect.addEventListener("click", e =>
    toggleListVisibility(e)
  );
  dropdownSelect.addEventListener("keydown", e =>
    toggleListVisibility(e)
  );

  listItems.forEach(item => listItemIds.push(item.id));

  listItems.forEach(item => {
    item.addEventListener("click", e => {
      setSelectedListItem(e);
      closeList();
      window.location = e.target.dataset.value;
    });

    item.addEventListener("keydown", e => {
      switch (e.key) {
        case ENTER_KEY:
          setSelectedListItem(e);
          closeList();
          window.location = e.target.dataset.value;
          return;

        case SPACEBAR_KEY[0]:
          e.preventDefault();
          setSelectedListItem(e);
          closeList();
          window.location = e.target.dataset.value;
          return;

        case SPACEBAR_KEY[1]:
          e.preventDefault();
          setSelectedListItem(e);
          closeList();
          window.location = e.target.dataset.value;
          return;

        case DOWN_ARROW_KEY:
          e.preventDefault();
          focusNextListItem(DOWN_ARROW_KEY);
          return;

        case UP_ARROW_KEY:
          e.preventDefault();
          focusNextListItem(UP_ARROW_KEY);
          return;

        case ESCAPE_KEY[0]:
          closeList();
          return;

        case ESCAPE_KEY[1]:
          closeList();
          return;

        default:
          return;
      }
    });
  });

  function setSelectedListItem(e) {
    let selectedTextToAppend;
    if (e.target.innerText.includes("English")) {
      selectedTextToAppend = document.createTextNode("Change");
    } else {
      selectedTextToAppend = document.createTextNode(e.target.innerText);
    }
    dropdownSelectedNode.innerHTML = null;
    dropdownSelectedNode.appendChild(selectedTextToAppend);
  }

  function closeList() {
    list.classList.remove("open");
    dropdownArrow.classList.remove("expanded");
    listContainer.setAttribute("aria-expanded", false);
  }

  function toggleListVisibility(e) {
    e.preventDefault();
    let openDropDown =
      SPACEBAR_KEY.includes(e.key) || e.key === ENTER_KEY;

    if (ESCAPE_KEY.includes(e.key)) {
      closeList();
    }

    if (e.type === "click" || openDropDown) {
      list.classList.toggle("open");
      dropdownArrow.classList.toggle("expanded");
      listContainer.setAttribute(
        "aria-expanded",
        list.classList.contains("open")
      );
    }

    if (e.key === DOWN_ARROW_KEY) {
      focusNextListItem(DOWN_ARROW_KEY);
    }

    if (e.key === UP_ARROW_KEY) {
      focusNextListItem(UP_ARROW_KEY);
    }
  }

  function focusNextListItem(direction) {
    const activeElementId = document.activeElement.id;
    if (activeElementId === "dropdown__selected") {
      document.querySelector(`#${listItemIds[0]}`).focus();
    } else {
      const currentActiveElementIndex = listItemIds.indexOf(
        activeElementId
      );
      if (direction === DOWN_ARROW_KEY) {
        const currentActiveElementIsNotLastItem =
          currentActiveElementIndex < listItemIds.length - 1;
        if (currentActiveElementIsNotLastItem) {
          const nextListItemId = listItemIds[currentActiveElementIndex + 1];
          document.querySelector(`#${nextListItemId}`).focus();
        }
      } else if (direction === UP_ARROW_KEY) {
        const currentActiveElementIsNotFirstItem =
          currentActiveElementIndex > 0;
        if (currentActiveElementIsNotFirstItem) {
          const nextListItemId = listItemIds[currentActiveElementIndex - 1];
          document.querySelector(`#${nextListItemId}`).focus();
        }
      }
    }
  }

  window.addEventListener('click', (e) => {
    const openMenus = document.querySelectorAll(".dropdown .dropdown__list.open");
    if (!e.target.closest(".dropdown")) {
      if (openMenus.length) {
        openMenus.forEach(element => {
          element.classList.remove('open');
        });
      }
    }
  });
}

$(document).ready(function () {
  "use strict";
  $(document).foundation();
  $("input, textarea").placeholder();
  inputFocus();
  styledSelect();
  styledCheckboxAndRadio();
  toggleSearch();
  headerDropdown();
  headerSubmenus();
  questionnaire();
  loadIframe();
  imageParallax();
  dropdownFunction('.toggle-nav-dropdown', '#nav-dropdown');
  popupModalInfo();
  showAllServices();
  languageSwitcher();
});

$(window).on("load", function () {
  "use strict";
  equalizerReflow();
  setTimeout(function () {
    $("body").removeClass("loading");
    equalizerReflow();
  }, 600);
  resizingClass();
  heroBlur();
  stickyScroll();
});
