var videoPopup = function () {
  "use strict";
  var selector = 'video-popup';
  if ($('.' + selector).length) {
    var $selector = $('.' + selector),
      $body = $('body'),
      openClass = selector + '-open',
      overlayClass = selector + '-overlay',
      lockScreenClass = 'lock-screen',
      currentScrollPosition = 0,
      parseVideoURL = function (url) {
        var video = {},
          regex = new RegExp("(http:|https:|)\\/\\/(player.|www.)?(vimeo\\.com|youtu(be\\.com|\\.be|be\\.googleapis\\.com))\\/(video\\/|embed\\/|watch\\?v=|v\\/)?([A-Za-z0-9._%-]*)(\\&\\S+)?");

        video.id = url.match(regex)[6];

        if (url.indexOf("youtu") !== -1) {
          video.provider = "youtube";
          video.iframe = '<iframe src="//www.youtube-nocookie.com/embed/' + video.id + '?autoplay=1" frameborder="0" allowfullscreen></iframe>';
        } else {
          video.provider = "vimeo";
          video.iframe = '<iframe src="//player.vimeo.com/video/' + video.id + '?autoplay=1" frameborder="0" webkitAllowFullScreen mozallowfullscreen autoPlay allowFullScreen></iframe>';
        }

        return video;
      },
      openVideoPopup = function ($this) {
        var thisVideoLink = $this.attr('href'),
          video = parseVideoURL(thisVideoLink),
          videoPopupOverlay = '<div class="' + overlayClass + '"><a href="#" class="' + overlayClass + '__close"><i>&times;</i></a><div class="table"><div class="table__cell"><div class="' + overlayClass + '__content"><div class="media"><div class="media__container">' + video.iframe + '</div></div></div></div></div></div>';

        if (!$body.hasClass(openClass)) {
          currentScrollPosition = $(window).scrollTop();
          $body.append(videoPopupOverlay);
          $body.addClass(openClass);
        }

      },
      closeVideoPopup = function ($this) {
        if ($body.hasClass(openClass)) {
          $body.removeClass(openClass);
          $this.remove();
          $('html, body').scrollTop(currentScrollPosition);
        }
      };


    $selector.on('click', function (e) {
      e.preventDefault();

      openVideoPopup($(this));

      return false;
    });

    $(document).on('click', '.' + overlayClass + '__close', function (e) {
      e.preventDefault();
      closeVideoPopup($(this).parents('.' + overlayClass));
      return false;
    });
  }
};

$(document).ready(function () {
  "use strict";
  videoPopup();
});
