<template>
  <div class="category-search-meta">
    <div class="page-info">
      <div v-if="totalItems > 0">
        <span class="items">{{ 'Found' | trans }} <span class="items__amount">{{ totalItems }}</span> {{ 'results' | trans }}</span>
      </div>
    </div>
    <search-sorting
      :sort-by-text="sortByText"
      :sorting.sync="searchSorting"
      :options="sortingOptions"
    ></search-sorting>
  </div>
</template>

<script>
import Vue from 'vue';
import SearchSorting from './SearchSorting'

export default {
  name: 'CategorySearchMeta',
  props: {
    totalItems: {
      type: Number,
      default: 0
    },
    itemOffset: {
      type: Number,
      default: 0
    },
    elementsPerPage: {
      type: Number,
      default: 0
    },
    'sectionType': {
      type: Array,
      default: []
    },
    sortingOptions: {
      type: Array,
      default: () => [
        {
          title: 'Newest first',
          value: 'postdate desc'
        },
        {
          title: 'Oldest first',
          value: 'postdate asc'
        },
        {
          title: 'Title A-Z',
          value: 'title asc'
        },
        {
          title: 'Title Z-A',
          value: 'title desc'
        }
      ]
    },
    sortByText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchSorting: ''
    }
  },
  watch: {
    searchSorting: function(val) {
      this.$emit('update:searchSorting', val)
    }
  },
  components: {
    SearchSorting
  },
};
</script>
