var trackEvents = function() {
  "use strict";
  var $location = window.location.href,
  links = $('.js-track-link'),
  submitButtons = $('form div.submit input[type="submit"]'),
  searchForm = $('.header-search form, .searchPage form');

  links.on('click', function(e) {

    var $link = $(this),
    $trackCategory = $($link).data("track-category");


    if ($trackCategory == "email") {
      ga('send', 'event', 'E-mail link', 'click', $location);
    } else if ($trackCategory == "phone") {
      ga('send', 'event', 'Phone number link', 'click', $location);
    } else if ($trackCategory == "video") {
      ga('send', 'event', 'Video', 'click', $location);
    }

  });

  submitButtons.on('click', function(e) {
    ga('send', 'event', 'Form submit button', 'click', $location);
  });

  searchForm.submit( function(e) {
    var searchValue = $(this).find('input[name="q"]').val();
    ga('send', 'event', 'Header search submittal', 'submit', searchValue);
  });


};

$(document).ready(function() {
  "use strict";
  trackEvents();
});
