
window.addEventListener('load', function() {
    var servicesWrappers = document.querySelectorAll('.services-wrapper');

    if(servicesWrappers) {
        servicesWrappers.forEach(function(slider) {
            var flkty = new Flickity( slider, {
                // options
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                watchCSS: true,
                groupCells: true
            });    
        })
    }
})
