import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment';
import transFilter from 'vue-trans';

import { configureApi, apiToken, apiUrl, getQueryParam, executeQuery } from './_searchCommon';
import CategorySearchMeta from '../vue/CategorySearchMeta';
import SearchPageList from '../vue/SearchPageList';
import Pagination from '../vue/Pagination';

Vue.use(transFilter);

const siteLang = document.documentElement.lang;

moment.locale(siteLang);
moment.weekdays(true);

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchResults: [],
    sections: [],
    searchSorting: 'postdate desc',
    pagination: {
      totalCount: 0,
      pages: [],
      hasNext: false,
      hasPrev: false,
      nextPageUrl: '',
      prevPageUrl: '',
      elementsPerPage: 12,
      currentPage: 1
    },
    isLoading: true,
    isReady: false,
    site: _.get(window, 'craftData.site', window.siteHandle),
  };
}

var filterChangeTimer;

var generalSearch = function () {

  const searchSections = ["pages", "news"];
  const searchEntries = ['pages_page_Entry', 'pages_parentPage_Entry', 'pages_landingPage_Entry', 'news_article_Entry', 'news_event_Entry'];
  let searchQueries = ''
  _.each(searchEntries, (entryType) => {
    searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        sectionId
        url
        postDate
      }`
  });

  // The query to search for entries in Craft
  const searchQuery =
    `
  query searchQuery($section: [String], $limit: Int, $needle: String, $offset: Int)
    {
      entries(section: $section limit: $limit search: $needle offset: $offset) {
        id
        title
        sectionId
        url
        ${searchQueries}
      }
      entryCount(limit: $limit search: $needle offset: $offset)
    }
    `;

  new Vue({
    el: document.getElementById('searchPage'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      SearchPageList,
      CategorySearchMeta,
      Pagination,
    },
    beforeCreate: function () {
    },
    created: function () {
      const sections = JSON.parse(document.getElementById('searchPage').dataset.sections);
      this.sections = sections;

      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if (!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if (!!categoryIdParam) {
        this.searchCategory.id = categoryIdParam;
      }
      if (!!pageParam) {
        this.pagination.currentPage = parseInt(pageParam);
      }
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function () {
    },
    watch: {
      'pagination.totalPages': function (val) {
        this.updatePagination();
      },
      'pagination.currentPage': function (val) {
        this.scrollup();
        this.pagination.offset = (this.pagination.currentPage - 1) * this.pagination.elementsPerPage;
        this.performSearch();
      },
      searchQuery: function (val, oldVal) {
        if (!!oldVal && val !== oldVal) {
          this.pagination = _.get(getcategorySearchLandingData(), 'pagination');
        }
        this.performSearch();
      },
      searchSorting: function () {
        this.scrollup();
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      sortSearch(entries) {
        if (!!entries) {
          _.forEach(entries, function (entry) {
            const category = _.get(entry, 'newsCategory') || _.get(entry, 'productCategory');
            if (!!category) {
              entry.categories = category;
            }
          })
        }
      },
      performSearch() {
        var self = this;

        if (self.searchQuery === '') {
          self.searchResults = [];
          self.isSearchDropdownOpen = false;
          return true;
        }

        self.isLoading = true;
        self.setHistory();
        const searchTitle = !!self.searchQuery ? `title:* ${self.searchQuery}* ` : '';
        const searchCategory = !!self.searchQuery && `categories: "${self.searchQuery}" OR categories:: "${self.searchQuery}"`;
        const searchTag = !!self.searchTag && `tags:${self.searchQuery} OR tags::${self.searchQuery} `;

        const searchString = _.compact([searchTitle, searchCategory, searchTag]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          section: searchSections,
          site: self.site,
          needle: searchString,
          limit: self.pagination.elementsPerPage || 24,
          offset: (self.pagination.currentPage - 1) * self.pagination.elementsPerPage,
          orderBy: self.searchSorting,
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function () {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            self.pagination.totalCount = dataPath.entryCount;
            self.pagination.totalPages = Math.ceil(Number(dataPath.entryCount) / (self.pagination.elementsPerPage || 24));;

            self.sortSearch(dataPath.entries);

            if (!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        var self = this;
        var paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (!!_.get(self.searchCategory, 'slug')) {
          paramString += !!paramString ? ('&cat=' + self.searchCategory.slug) : (paramString += '?cat=' + self.searchCategory.slug);
          paramString += !!paramString ? ('&cat-id=' + self.searchCategory.id) : (paramString += '?cat-id=' + self.searchCategory.id);
        }
        if (!!self.pagination.currentPage) {
          paramString += !!paramString ? ('&page=' + self.pagination.currentPage) : (paramString += '?page=' + self.pagination.currentPage);
        }
        if (window.history && window.history.replaceState) {
          var pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          var url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        var top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
      updatePagination() {
        const { pagination, currentPage, totalPages } = this;
        pagination.pages = [];
        for (let i = 0; i < totalPages; i++) {
          const index = i + 1;
          pagination.pages.push({
            text: index,
            url: this.getPageUrl(index),
            current: index === currentPage
          });
        }
        pagination.hasNext = currentPage < totalPages;
        pagination.hasPrev = currentPage > 1;
      }
    },
  });
};

!!document.getElementById('searchPage') && generalSearch();
